import { Link } from '@remix-run/react'
import { toast } from 'sonner'

const navigation = {
  product: [
    { name: 'Features', to: { pathname: '/', hash: '#features' } },
    { name: 'Pricing', to: { pathname: '/', hash: 'pricing' } },
    { name: 'FAQ', to: '/faq' },
  ],
  company: [
    { name: 'About us', to: { pathname: '/', hash: '#about' } },
    { name: 'Partners', to: { pathname: '/', hash: '#partners' } },
    { name: 'Support', to: '/support' },
  ],
  legal: [
    { name: 'Terms of Service', to: '/terms' },
    { name: 'Privacy Policy', to: '/privacy' },
    { name: 'Imprint', to: '/imprint' },
  ],
}

export function Footer() {
  return (
    <footer aria-labelledby='footer-heading'>
      <h2 id='footer-heading' className='sr-only'>
        Footer
      </h2>
      <div className='mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8 lg:py-32'>
        <div className='xl:grid xl:grid-cols-3 xl:gap-8'>
          <h3 className='text-xl font-semibold'>fuse.space</h3>

          <div className='mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0'>
            <div className='md:grid md:grid-cols-2 md:gap-8'>
              <div>
                <h3 className='text-sm font-semibold leading-6 text-white'>
                  Product
                </h3>
                <ul className='mt-6 space-y-4'>
                  {navigation.product.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.to}
                        className='text-sm leading-6 text-gray-300 hover:text-white'
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className='mt-10 md:mt-0'>
                <h3 className='text-sm font-semibold leading-6 text-white'>
                  Company
                </h3>

                <ul className='mt-6 space-y-4'>
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.to}
                        className='text-sm leading-6 text-gray-300 hover:text-white'
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className='md:grid md:grid-cols-2 md:gap-8'>
              <div>
                <h3 className='text-sm font-semibold leading-6 text-white'>
                  Legal
                </h3>
                <ul className='mt-6 space-y-4'>
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.to}
                        className='text-sm leading-6 text-gray-300 hover:text-white'
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className='mt-10 md:mt-0'>
                <h3 className='text-sm font-semibold leading-6 text-white'>
                  Contact
                </h3>

                <p className='mt-6'>
                  fuse.space GmbH <br /> Feldstraße 66 <br />
                  20359 Hamburg Germany
                </p>

                <button
                  type='button'
                  className='underline mt-4'
                  onClick={async () => {
                    await navigator.clipboard.writeText('info@fuse.space')
                    toast('Email Copied', {
                      description:
                        'We copied our email to your clipboard. Say hi!',
                    })
                  }}
                >
                  info@fuse.space
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
