import { Icons } from '@/components/icons'
import { buttonVariants } from '@/components/ui/button'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import { cn } from '@/lib/utils'
import { Link, type LinkProps } from '@remix-run/react'
import * as React from 'react'

type NavItem = {
  name: string
  to: LinkProps['to']
}

const navigation: Array<NavItem> = [
  { name: 'Features', to: { pathname: '/', hash: '#features' } },
  { name: 'Pricing', to: { pathname: '/', hash: '#pricing' } },
  { name: 'About us', to: { pathname: '/', hash: '#about' } },
  { name: 'FAQ', to: '/faq' },
]

export function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)

  const [isAtTop, setIsAtTop] = React.useState(true)

  React.useEffect(() => {
    if (typeof window === 'undefined') return

    const handleScroll = () => {
      if (window.scrollY > 1) {
        setIsAtTop(false)
      } else {
        setIsAtTop(true)
      }
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <header
      className={cn(
        'fixed top-0 left-0 right-0 bg-background z-50',
        !isAtTop && 'border-b',
      )}
    >
      <nav
        className='container lg:px-16 xl:px-32 flex items-center justify-between py-6'
        aria-label='Global'
      >
        <div className='flex lg:flex-1'>
          <Link to='/' className='text-xl lg:text-2xl'>
            <Icons.Fusespace />
          </Link>
        </div>

        <div className='flex lg:hidden'>
          <button
            type='button'
            className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-muted-foreground'
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className='sr-only'>Open main menu</span>
            <Icons.FullBurger className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='hidden lg:flex lg:gap-x-16'>
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.to}
              className='text-muted-foreground'
            >
              {item.name}
            </Link>
          ))}
        </div>

        <div className='hidden lg:flex lg:flex-1 gap-4 lg:justify-end'>
          <Link
            to='/auth/login'
            prefetch='intent'
            className={cn(
              buttonVariants({ variant: 'outline' }),
              'border-muted-foreground',
            )}
          >
            Login
          </Link>
          <Link
            to='/auth/signup/'
            prefetch='intent'
            className={cn(buttonVariants({ variant: 'cta' }), 'font-semibold')}
          >
            Sign Up
          </Link>
        </div>
      </nav>

      <Dialog
        open={mobileMenuOpen}
        onOpenChange={() => setMobileMenuOpen(!mobileMenuOpen)}
      >
        <DialogContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          className='fixed left-[0%] top-[0%] flex flex-col h-full max-w-full duration-700 translate-x-0 translate-y-0 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-left-[0%] data-[state=closed]:slide-out-to-top-[0%] data-[state=open]:slide-in-from-left-[0%] data-[state=open]:slide-in-from-top-[0%]'
        >
          <div className='text-xl'>
            <span className='font-bold'>fuse</span>.<span>space</span>
          </div>

          <div className='mt-6'>
            <div className='divide-y divide-muted-foreground'>
              <div className='space-y-4 py-6'>
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.to}
                    onClick={() => {
                      setMobileMenuOpen(false)
                    }}
                    className='block rounded-lg text-center py-2 text-xl hover:bg-muted'
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className='pt-6 space-y-4'>
                <Link
                  to='/auth/login'
                  prefetch='render'
                  className={cn(
                    buttonVariants({ variant: 'outline' }),
                    'w-full',
                  )}
                >
                  Log in
                </Link>

                <Link
                  to='/auth/sign-up'
                  prefetch='render'
                  className={cn(buttonVariants({ variant: 'cta' }), 'w-full')}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </header>
  )
}
