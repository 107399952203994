import type { HeadersFunction } from '@remix-run/node'
import { Outlet } from '@remix-run/react'
import { Footer } from './footer'
import { Navbar } from './navbar'

export const headers: HeadersFunction = () => [
  ['Cache-Control', 'public, stale-while-revalidate=604800, max-age=300'],
]

export default function MarketingLayout() {
  return (
    <div className='h-full flex flex-col'>
      <Navbar />

      <main className='mt-[98px] flex-grow'>
        <Outlet />
      </main>

      <Footer />
    </div>
  )
}
